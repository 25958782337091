import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { NavigationStack } from '~/store/navigation';

export interface NavigationHooksStack extends NavigationStack {}

interface NavigationHooksMixin {
  navigationStack?: NavigationHooksStack | null;
  onActivateNavigationHooks(): void;
  onDectivateNavigationHooks(): void;
}

@Component<NavigationHooksMixin>({
  name: 'navigation-hooks-mixin',

  created() {
    this.activateNavigationHooks();
  },

  activated() {
    this.activateNavigationHooks();
  },

  beforeDestroy() {
    this.deactivateNavigationHooks();
  },

  deactivated() {
    this.deactivateNavigationHooks();
  },
})
// eslint-disable-next-line no-redeclare
class NavigationHooksMixin extends Vue {
  @Watch('navigationStack')
  protected navigationStackChangeHandler(
    current?: NavigationHooksStack | null,
    old?: NavigationHooksStack | null,
  ) {
    if (current && old) {
      if (current.id !== old.id) {
        this.$navigation.removeStack(old);
        this.$navigation.addStack(current);
      }
    } else if (current) {
      this.$navigation.addStack(current);
    } else if (old) {
      this.$navigation.removeStack(old);
    }
  }

  private activateNavigationHooks() {
    const { navigationStack } = this;
    navigationStack && this.$navigation.addStack(navigationStack);
    this.onActivateNavigationHooks && this.onActivateNavigationHooks();
  }

  private deactivateNavigationHooks() {
    const { navigationStack } = this;
    navigationStack && this.$navigation.removeStack(navigationStack);
    this.onDectivateNavigationHooks && this.onDectivateNavigationHooks();
  }
}

export default NavigationHooksMixin;
