import { Component, mixins } from 'nuxt-property-decorator';
import { MetaInfo } from 'vue-meta';

import { HHotelToBooking, HPortalItem } from '~/components';
import NavigationHooksMixin from '~/mixins/navigation-hooks';
import {
  CrossSearchHotelMaster,
  HotelBasicInfo,
  HotelBrandDetail,
} from '~/schemes';
import { generateHreflang } from '~/helpers';

@Component<BrandViewRoot>({
  scrollToTop: true,
  async asyncData({ $hotel, params, error, $env, res, $api, $error }) {
    try {
      const { brand_slug } = params;
      const hit = $hotel.brands.find((b) => b.slug === brand_slug);
      /** 横断検索のマスタ情報を取得 */
      const crossSearch = await $api.crossSearch.all();

      if (!hit) {
        error({ statusCode: 404 });
        return;
      }

      if (hit.url && $env.isProduction) {
        // ブランドURL（外部）が登録されていて、かつ本番環境であればリダイレクトする
        if (process.browser) {
          location.replace(hit.url);
        } else {
          /**
           * ここでヘッダ送信（end()）しちゃうとserver側のmiddlewareチェーンが例外吐くけど、
           * これしか現状良い場所がないので気にしない事にする
           * NuxtのFeatureに期待か別の仕組み検討
           */
          res.writeHead(302, { Location: hit.url });
          res.end();
        }
        return;
      }

      const brand = await $hotel.brandDetailBySlug(brand_slug);
      if (!brand) {
        error({ statusCode: 404 });
        return;
      }

      // ↑でリダイレクト処理しているのに、なぜかこっちでも同じようなことしているのが謎
      const brandUrl = brand.url;
      if (!brand.__preview && brandUrl) {
        const internalPath = `${params.lang}/brands/${brand_slug}/`;
        if (
          !brandUrl
            .replace($env.origin, '')
            .replace(/^\//, '')
            .startsWith(internalPath)
        ) {
          if (process.browser) {
            location.replace(brandUrl);
          } else {
            /**
             * ここでヘッダ送信（end()）しちゃうとserver側のmiddlewareチェーンが例外吐くけど、
             * これしか現状良い場所がないので気にしない事にする
             * NuxtのFeatureに期待か別の仕組み検討
             */
            res.writeHead(302, { Location: brandUrl });
            res.end();
          }
          return;
        }
      }

      await $hotel.setCurrentBrand(brand);

      const hotels = $hotel.hotelsByBrandId(brand.id).filter((h) => !!h.name);
      return { brand, hotels, crossSearch };
    } catch (_err) {
      $error.throw(_err);
    }
  },
  provide() {
    return {
      brandViewRoot: this,
    };
  },
  head() {
    const lang = this.$language.current;
    const { brandHeaderIsActive, brand } = this;
    const htmlAttrs: MetaInfo['headAttrs'] = {
      'data-foundation-background': '1',
    };

    /** head内にhreflangを設定 */
    const link: MetaInfo['link'] = generateHreflang(this, { brand });

    if (brandHeaderIsActive) {
      htmlAttrs['data-brand-header-active'] = '1';
    }
    let favicon: string | undefined;

    if (brand && brand.favicon) {
      favicon = brand.favicon;
    }
    const { siteName, description, image } = brand.meta;

    const meta: MetaInfo['meta'] = [
      { hid: 'og:site_name', property: 'og:site_name', content: siteName },
      { hid: 'og:title', property: 'og:title', content: siteName },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `${this.$navigation.origin}/${lang}/brands/${brand.slug}/`,
      },
    ];

    if (description) {
      meta.push(
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
      );
    }

    if (image) {
      meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.$res.img(image),
      });
    }

    if (favicon) {
      link.push({
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$res.img(favicon),
      });
    }

    return {
      titleTemplate: (titleChunk) => {
        if (!titleChunk) {
          return siteName;
        }
        return `${titleChunk} | ${siteName}`;
      },
      meta,
      htmlAttrs,
      link,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  render() {
    return (
      <div staticClass="my-brand-root-view" id={this.domId}>
        <nuxt-child />
        <HPortalItem to="portal-footer">
          <HHotelToBooking
            staticClass="my-brand-root-view__portal-footer"
            brand={this.brand}
            eventId="fixedarea"
          />
        </HPortalItem>
      </div>
    );
  },
})
export default class BrandViewRoot extends mixins<NavigationHooksMixin>(
  NavigationHooksMixin,
) {
  brand: HotelBrandDetail = null as any;
  hotels: HotelBasicInfo[] = null as any;
  crossSearch: CrossSearchHotelMaster = null as any;
  private isMounted: boolean = false;

  // pageTopScrollActiveSectionId: sectionId = 'default';

  get domId() {
    return `h-brand--${this.brand.slug}`;
  }

  get currentRouteName() {
    return this.$route.name;
  }

  get isBrandTop() {
    return this.currentRouteName === 'lang-brands-brand_slug-index';
  }

  get logoIsScrollOuted() {
    if (!this.isMounted) return false;
    return this.$window.scrollTop > 250;
  }

  get needHiddenBrandHeader() {
    return this.brand.useHeaderLogo ? this.logoIsScrollOuted : false;
  }

  get brandHeaderIsActive() {
    return !this.isBrandTop || this.needHiddenBrandHeader;
  }

  onActivateNavigationHooks() {
    this.$hotel.setCurrentBrand(this.brand);
  }

  onDectivateNavigationHooks() {
    this.$hotel.setCurrentBrand(null);
  }
}
